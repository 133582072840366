import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import { useMapsList } from "@/game-val/utils.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const MapsGrid = styled("div")`
  display: grid;
  grid-gap: var(--sp-4);
  grid-template-columns: repeat(3, 1fr);
  ${tablet} {
    grid-template-columns: 50% 50%;
  }
  ${mobile} {
    grid-template-columns: 100%;
  }
`;

const MapBlock = styled("a")`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--shade8);
  border-radius: var(--br-lg);
  overflow: hidden;
  div {
    max-width: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      &:hover {
        transform: scale(1.05);
        transition: transform 350ms;
      }
    }
  }
  h2 {
    margin: var(--sp-2) 0px;
  }
`;

export default function Maps() {
  const { t } = useTranslation();

  const mapsList = useMapsList();

  return (
    <PageContainer>
      <PageHeader title={t("common:navigation.lineups", "Lineups")} />
      <MapsGrid>
        {mapsList.map(({ key, href, text, images }) => (
          <MapBlock href={href} key={key}>
            <div>
              <img src={images?.hero} />
            </div>
            <h2 className="type-subtitle1">{text}</h2>
          </MapBlock>
        ))}
      </MapsGrid>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["val:meta.maps.title", "VALORANT Interactive Map Guides"],
    description: [
      "val:meta.maps.description",
      "Perfect your VALORANT gameplay with our dedicated lineups, showcasing the best agent ability placements for each map. Leverage these insights to gain a competitive edge in your matches.",
    ],
    subtitle: true,
  };
}
